import { template as template_02cc1626be1344279d56b8afbe5fd491 } from "@ember/template-compiler";
const FKText = template_02cc1626be1344279d56b8afbe5fd491(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
