import { template as template_99b4b8ab4ab54d2fb5305fe09a794d3e } from "@ember/template-compiler";
const FKControlMenuContainer = template_99b4b8ab4ab54d2fb5305fe09a794d3e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
