import { template as template_56e5decf6195486db4e9a56c849ecf10 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_56e5decf6195486db4e9a56c849ecf10(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
