import { template as template_fa3f484b7c5a46a58de035a6fc972077 } from "@ember/template-compiler";
const FKLabel = template_fa3f484b7c5a46a58de035a6fc972077(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
